
// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
//@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
/*$pv-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$pv-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$pv-warn: mat.m2-define-palette(mat.$m2-red-palette);

//$pv-density: mat.m2-define-density();

// Create the theme object (a Sass map containing all of the palettes).
$pv-theme: mat.m2-define-light-theme($pv-primary, $pv-accent, $pv-warn);*/

//$pv-theme: mat.define-theme(( color: ( theme-type: light, primary: mat.$azure-palette, ), typography: ( brand-family: '"Source Sans Pro", sans-serif'), density: (scale: -2)));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//html {
   // @include mat.all-component-themes($pv-theme);
//}


// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: ( base: black, divider: $dark-dividers, dividers: $dark-dividers, disabled: $dark-disabled-text, disabled-button: rgba($dark-text, 0.26), disabled-text: $dark-disabled-text, elevation: black, secondary-text: $dark-accent-text, hint-text: $dark-disabled-text, accent-text: $dark-accent-text, icon: $dark-accent-text, icons: $dark-accent-text, text: $dark-primary-text, slider-min: $dark-primary-text, slider-off: rgba($dark-text, 0.26), slider-off-active: $dark-disabled-text, );

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: ( base: $light-text, divider: $light-dividers, dividers: $light-dividers, disabled: $light-disabled-text, disabled-button: rgba($light-text, 0.3), disabled-text: $light-disabled-text, elevation: black, hint-text: $light-disabled-text, secondary-text: $light-accent-text, accent-text: $light-accent-text, icon: $light-text, icons: $light-text, text: $light-text, slider-min: $light-text, slider-off: rgba($light-text, 0.3), slider-off-active: rgba($light-text, 0.3), );

// Background config
// Light bg
$light-background: #ffffff;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: ( background: $light-background, status-bar: $light-bg-darker-20, app-bar: $light-bg-darker-5, hover: $dark-bg-alpha-4, card: $light-bg-lighter-5, dialog: $light-bg-lighter-5, tooltip: $dark-bg-tooltip, disabled-button: $dark-bg-alpha-12, raised-button: $light-bg-lighter-5, focused-button: $dark-focused, selected-button: $light-bg-darker-20, selected-disabled-button: $light-bg-darker-30, disabled-button-toggle: $light-bg-darker-10, unselected-chip: $light-bg-darker-10, disabled-list-option: $light-bg-darker-10, );

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#ffffff, 0.04);
$light-bg-alpha-12: rgba(#ffffff, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: ( background: $dark-background, status-bar: $dark-bg-lighter-20, app-bar: $dark-bg-lighter-5, hover: $light-bg-alpha-4, card: $dark-bg-lighter-5, dialog: $dark-bg-lighter-5, tooltip: $dark-bg-lighter-20, disabled-button: $light-bg-alpha-12, raised-button: $dark-bg-lighter-5, focused-button: $light-focused, selected-button: $dark-bg-lighter-20, selected-disabled-button: $dark-bg-lighter-30, disabled-button-toggle: $dark-bg-lighter-10, unselected-chip: $dark-bg-lighter-20, disabled-list-option: $dark-bg-lighter-10, );

// Compute font config
//@include mat.core($fontConfig);

// Theme Config

body {
    --primary-color: #0066cc;
    --primary-lighter-color: #0096e5;
    --primary-darker-color: #0049b9;
    --text-primary-color: #{$light-primary-text};
    --text-primary-lighter-color: #{$light-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: ( main: #0066cc, lighter: #0096e5, darker: #0049b9, 200: #0066cc, // For slide toggle,
contrast : ( main: $light-primary-text, lighter: $light-primary-text, darker: $light-primary-text, ) );
$theme-primary: mat.m2-define-palette($mat-primary, main, lighter, darker);


body {
    --accent-color: #0066cc;
    --accent-lighter-color: #b3d1f0;
    --accent-darker-color: #0049b9;
    --text-accent-color: #{$light-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: ( main: #0066cc, lighter: #b3d1f0, darker: #0049b9, 200: #0066cc, // For slide toggle,
contrast : ( main: $light-primary-text, lighter: $dark-primary-text, darker: $light-primary-text, ) );
$theme-accent: mat.m2-define-palette($mat-accent, main, lighter, darker);


body {
    --warn-color: #e48900;
    --warn-lighter-color: #ffcc00;
    --warn-darker-color: #d96c00;
    --text-warn-color: #{$dark-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$dark-primary-text};
    --mat-form-field-filled-with-label-container-padding-top: 20px;
    --mat-form-field-filled-with-label-container-padding-bottom: 0;
    --mdc-icon-button-state-layer-size: 42px;
    --mat-form-field-container-height: 42px;
    --mat-form-field-container-text-line-height: 12px;
    //--mat-form-field-container-vertical-padding: ;
}

$mat-warn: ( main: #e48900, lighter: #ffcc00, darker: #d96c00, 200: #e48900, // For slide toggle,
contrast : ( main: $dark-primary-text, lighter: $dark-primary-text, darker: $dark-primary-text, ) );
$theme-warn: mat.m2-define-palette($mat-warn, main, lighter, darker);


$theme: ( primary: $theme-primary, accent: $theme-accent, warn: $theme-warn, is-dark: false, foreground: $mat-light-theme-foreground, background: $mat-light-theme-background, );
/*$altTheme: ( primary: $theme-primary, accent: $theme-accent, warn: $theme-warn, is-dark: true, foreground: $mat-dark-theme-foreground, background: $mat-dark-theme-background, );*/

// Theme Init
@include mat.all-component-themes($theme);

/*.theme-alternate {
    @include mat.all-component-themes($altTheme);
}*/

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
/*.mat-raised-button, .mat-stroked-button, .mat-flat-button {
    padding: 0 1.15em;
    margin: 0 .65em;
    min-width: 3em;
    line-height: 36.4px
}

.mat-standard-chip {
    padding: .5em .85em;
    min-height: 2.5em;
}*/
/*
.material-icons {
    font-size: 24px;
    font-family: 'Material Icons', 'Material Icons';

    .mat-badge-content {
        font-family: 'Source Sans Pro';
    }
}*/
