@import 'custom-theme.scss';

body {
    font-family: "Source Sans Pro", sans-serif;
}

h1 {
    font-size: 3em;
    color: #333;
}

h2 {
    font-size: 2em;
    color: #555;
}

h3 {
    font-size: 1.6em;
    color: #555;
    margin-bottom: 10px;
}

h4 {
    font-size: 1.3em;
}

a {
    color: #003366;
    cursor: pointer;
}

a:hover {
    color: #FF9900;
}

header {
    margin: 15px 0;
}

#page-title {
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 50px;
    font-size: 3.4em;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 5px;
    clear: both;
}

#logo-contain {
    display: inline-block;
}

#logo {
    margin: 0 5px 0 0;
    width: 170px;
    height: 60px;
}

#login {
    float: right;
    margin-top: 28px;
}

#login .button {
    margin-left: 10px;
}

header nav {
    min-width: 550px;
}

nav.navbar, nav.sub-navbar {
    text-align: center;
    margin: 10px 0;
}

nav ul {
    width: 100%;
    display: flex;
}

nav ul li {
    display: inline-block;
    flex-grow: 1;
}

nav.navbar ul a {
    padding: 15px 0;
    background-color: #0066cc;
    color: #FFF;
    text-decoration: none;
    display: block;
    font-size: 1.2em;
    margin: 0 1px;
}

nav.navbar ul li:first-of-type a {
    margin-left: 0px;
}

nav.navbar ul li:last-of-type a {
    margin-right: 0px;
}

.navbar a.active, .navbar a:hover {
    background-color: #0096e5;
}

nav.sub-navbar ul li:not(:first-child) {
    border: 0 solid #777;
    border-left-width: 1px;
    margin-left: -1px;
}

nav.sub-navbar ul a {
    padding: 15px 0;
    color: #444;
    text-decoration: none;
    display: block;
    font-size: 1.2em;
}

nav.sub-navbar ul li {
    margin: 15px 0;
}

nav.sub-navbar ul li span {
    display: inline-block;
    margin: -15px 0;
    width: 100%;
}

.sub-navbar a.active, .sub-navbar a:hover {
    color: #e48900;
    border: solid 0;
    border-color: #FF9900;
    border-bottom-width: 2px;
}

.sub-navbar a:hover {
    color: #FF9900;
    border-color: #FFCC00;
}

#content {
    margin: 30px 0px 10px;
}

.search-option .button {
    width: 100%;
    padding: 10px 0;
    margin: 20px 0 10px;
    display: inline-block;
    text-align: center;
    font-size: 1.2em;
}

.search-option .alert {
    margin-bottom: 20px;
    display: inline-block;
}

mat-hint {
    color: #e48900;
}

.mat-mdc-form-field-focus-overlay, .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: unset !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 20px;
}

mat-form-field .mdc-text-field {
    padding: 0;
}

.mat-mdc-form-field, .mat-mdc-floating-label {
    letter-spacing: unset;
}

.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #40b8ff;
    --mdc-radio-selected-hover-icon-color: #40b8ff;
    --mdc-radio-selected-icon-color: #40b8ff;
    --mdc-radio-selected-pressed-icon-color: #40b8ff;
    --mat-mdc-radio-checked-ripple-color: #40b8ff;
}

.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-focus-icon-color: #40b8ff;
    --mdc-checkbox-selected-hover-icon-color: #40b8ff;
    --mdc-checkbox-selected-icon-color: #40b8ff;
    --mdc-checkbox-selected-pressed-icon-color: #40b8ff;
}

.mat-mdc-radio-button {
    margin: 0 20px;
}

.faux-mat-form-field mat-label {
    color: #888;
    font-size: 1.1em;
    margin-right: 10px;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
mat-table, .mdc-data-table__table, mat-table.mdc-data-table__table {
    margin: 0px auto;
    width: 100%;
    border: solid 1px #8bcfef;
}

.mat-mdc-table tbody, .mat-mdc-table tfoot, .mat-mdc-table thead, .mat-mdc-cell, .mat-mdc-footer-cell, .mat-mdc-header-row, .mat-mdc-row, .mat-mdc-footer-row, .mat-mdc-table .mat-mdc-header-cell {
    background: unset;
}

mat-header-row {
    color: #fff;
    text-align: left;
    background-color: #0096e5 !important;
    /*padding: 2px 5px;*/
}
mat-header-cell {
    color: #FFF !important;
}

/*.mdc-data-table__header-cell {
     padding: 0; 
}*/
mat-table .mdc-data-table__cell, mat-table .mdc-data-table__header-cell {
    padding: unset;
}

mat-cell.mat-mdc-cell {
    padding: 0 2px;
    color: inherit;
}


mat-cell:first-of-type, mat-footer-cell:first-of-type, mat-header-cell:first-of-type {
    padding-left: unset;
}

mat-cell:last-of-type, mat-footer-cell:last-of-type, mat-header-cell:last-of-type {
    padding-right: 10px;
}

mat-table mat-row, mat-table mat-header-row {
    min-height: 36px;
}

.mat-mdc-header-cell {
    font-size: unset;
    color: unset;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
mat-table .mat-mdc-select-value {
    color: #fff;
}

mat-cell div p {
    white-space: nowrap;
}
mat-row.local-appointment {
    color: #009900;
}
.mat-mdc-form-field.filter {
    display: block;
    width: 100%;
}

mat-header-row th.mat-mdc-header-cell {
    border-bottom-width: 0;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
    padding: unset;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix textarea{
    padding: 10px;
}

.mat-mdc-form-field-no-padding .mat-mdc-form-field-wrapper {
    margin-bottom: -0.5em;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 0 0 .5em 0;
}

.faux-mat-form-field > label > span:not(.mat-mdc-checkbox-label), .form-list-item > label > span:not(.mat-mdc-checkbox-label) {
    margin-left: 8px;
}
.form-list-item {
    margin: -10px 0;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled), .mdc-text-field--focused {
    background-color: unset !important;
}
input:-internal-autofill-selected{
    background-color: unset !important;
}

.mat-mdc-select-panel {
    background: #fff;
}

.mat-mdc-paginator .mat-mdc-select-value {
    font-size: 16px;
    padding-left: 10px;
}
.mat-mdc-select-arrow-wrapper {
    padding-right: 10px;
}

.clickable-row:hover {
    cursor: pointer;
    background-color: #cee9f7 !important; /*#009CE5;*/
}

.even-row {
    background-color: #FFF !important;
}

.odd-row {
    background-color: #f3f7f9 !important;
}

.accordian {
    margin-bottom: 15px;
}

.accordian-ctl {
    background-color: #416b82;
    color: #FFF;
    padding: 8px;
    text-align: center;
    font-size: 1.25em;
    cursor: pointer;
}

.accordian-ctl.active {
    background-color: #003366;
}

.accordian-ctl:hover {
    background-color: #0096e5;
}

.panel {
    border: solid 1px #72afec;
    min-height: 100px;
    padding: 15px 10px 10px;
    margin-bottom: 20px;
}

.panel-contain {
    max-width: 1000px;
    margin: 20px auto;
}

.panel-no-results {
    text-align: center;
    padding: 40px 0;
}


#producer-info span.label, #producer-details span.label {
    display: inline-block;
    margin-right: 5px;
    color: #0096e5;
    padding: 5px 0;
}


.draggable-list-item {
    padding: 8px 10px 8px 4px;
    margin: 3px 0;
    border: solid 1px #aaa;
    background-color: white;
}

.draggable-list-item > span {
    background-image: url("data:img/svg;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAECAYAAABY+sXzAAAARUlEQVQImXXMSwqAQBAD0df+RjyA9z+ka0caHBhFA9lUSEWtVUQMGFFwJPvLhlxbI+F9ePA0ni9Lsq/MPVybtTNP2LHABQXeFC/IvwvyAAAAAElFTkSuQmCC");
    background-repeat: repeat-y;
    padding-right: 25px;
    cursor: move;
}

.cdk-drag-preview {
    height: 25px !important;
}

.cdk-overlay-pane {
    width: unset !important;
}

pv-modal {
    display: none;
}

pv-state-select {
    margin-top: -6px;
    vertical-align: bottom;
}

.button {
    background-color: #FF9900; /*#003366;*/
    color: #FFF;
    padding: 5px 15px;
    cursor: pointer;
    border: 0;
    text-decoration: none;
}

.button:hover {
    background-color: #FFCC00; /*#6294c7;*/ /*#E2F4FD;*/
}

a.button:hover {
    color: #FFF;
}

.button.disabled, .button:disabled {
    background-color: #AFAFAF;
    cursor: not-allowed;
}

.button.disabled:hover, .button:disabled:hover {
    background-color: #AFAFAF;
}

.elevated-btn {
    background-color: #009900;
}

.elevated-btn:hover {
    background-color: #91de4b;
}

.warning-btn {
    background-color: #e84040;
}

.warning-btn:hover {
    background-color: #ea7171;
}

.delete-btn {
    cursor: pointer;
    width: 11px;
    height: 11px;
    display: inline-block;
    background: 0 0 no-repeat url("data:img/svg;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAWCAYAAAAW5GZjAAAA5klEQVQokZWQwQkCMRBFn0GwAZvYo0143YBHW/FoK/Em5GwjFuEWoKeRyU5YTYKSD8uSmTf5P7MSEZK8H4ATsAe2wATcgDMx3hWZYe8PQAA21HoBR2K8OrsxEGMLxOpBOWfWG7zXRgmS6rPjyVnGnHsZWMCs/UrGURrWJZjk7NU1WEaCydl66hvrgZtLe9T1tKyXAV3fuXPPs/0V2AGXjzdMdt5Zn/VX3n/SGCJyEJGntPW0foKHVBjHNjrXdWBQOBSNEswKCj+aQO304Id1pe6buzJ3baNrz/lTB42U36B/PWsdEeENvcvZizYiWZsAAAAASUVORK5CYII=");
}

.delete-btn:hover {
    background-position: 0 -11px;
}

.pointer {
    cursor: pointer;
}

.alert, .message {
    width: 100%;
    color: #e23333;
    background-color: #fbe1e1;
    padding: 5px 0;
    border-radius: 5px;
    text-align: center;
}

.alert-icon {
    cursor: pointer;
    background: 0 0 no-repeat url("data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAARCAYAAAA/mJfHAAABsklEQVQ4jaWTP0scQRjGf3u3u+ffRQmCQiRjwBEhQsAiXUwKU1iZQi2SLrGwSKGojaAYtEhiPkE+Qb5A6mvTpQgBl0BWbISTXJzckZzeefIOm8B5t8upTzN/3ud95jfDrlOpkiqjtAO8BdaDKKyneTPpUVZzwGo8piqVzCidA74Bd4EfwHgQhZXrkr2Kg0Qj8TpRiWRG6VvAd6DPXyxy+qFftn8Bo0EUHl+VbEuC3IdlOjYKuFNl2esDNq9EZpTWwFeyeD2fDsiMVTjfz1GauQM1zoCJIAr32yWTT8Hz509sUM7Fjv7CidQ84E1bZEbpKSDvdJ/Tk49wBqo2THz1gkvpkaJetgyPgyjMJ5IZpWX9Xub+0k8b1HDyQNXux9qL/YnXfAZMZoaq+C+KLe+fe1lE6uIDnrcMM0p3Abu2Ye0YpzPhz+mo23qsnbiviWwZGM5O/MWbNQ39l9/Ve2oQn/iBlf/PIEaj9CAQAr3dHw/JPvjTeDW3ObD2uZPygmTxG9BBFB79I9uWIHe61BTUikwkPvdJSaa9wGtLVrit7wFfpN76kdpSDbgvZO9uGGRBgb0LkgiDQXPzthMAAAAASUVORK5CYII=");
    width: 20px;
    height: 20px;
}

.message {
    color: #009900;
    background-color: #e0f1e0;
    text-align: left;
    margin: 5px 0;
}

.message p, .alert p {
    padding: 5px 10px;
}

.alert-color {
    color: #e23333;
}

.orange {
    color: #FF9900;
}

.med-gray {
    color: #555;
}

.white {
    color: #FFF;
}

.hidden {
    display: none;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.middle {
    vertical-align: middle;
}

.top {
    vertical-align: top;
}

.inline {
    display: inline-block;
}

.block {
    display: block;
}

.bold {
    font-weight: bold;
}

.italics {
    font-style: italic;
}

.underline {
    text-decoration: underline;
}

.proper-name, .uppercase {
    text-transform: capitalize;
}

.lowercase {
    text-transform: lowercase;
}

:not(mat-cell):not(mat-option).proper-name {
    display: inline-block; /* doesn't work on first letter if not block/inline-block */
}

.float-right {
    float: right;
}

.clear-float {
    clear: both;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right;
}

.center {
    text-align: center;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 48%;
    display: inline-block;
}

.half-width.first-half-option {
    padding-right: 2%;
}

.half-width.second-half-option {
    padding-left: 2%;
}

.third-width {
    width: 32%;
    display: inline-block;
}

.third-width.middle-option {
    padding: 0 2%;
}

html, body {
}
/*body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }*/


.pv-modal {
    /* modal container fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* z-index must be higher than .pv-modal-background */
    z-index: 1010;
    /* enables scrolling for tall modals */
    overflow: auto;
    text-align: center;
}

#message-overlay .pv-modal {
    z-index: 10000;
}

.pv-modal-background {
    /* modal background fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* semi-transparent black  */
    background-color: #000;
    opacity: 0.7;
    /* z-index must be below .pv-modal and above everything else  */
    z-index: 1000;
}

.cdk-global-overlay-wrapper, .cdk-overlay-container {
    z-index: 9999 !important;
}
/*******LOADING SPINNER STYLES********/
#loading-container {
    /*position: absolute;*/
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 9999999;
    /*background-color: RGBA(128,128,128,.5);*/
    /*padding-top: 20%;*/
    /*display: none;*/
}

.loading-spinner {
    color: #73c0e8;
    font-size: 90px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: 72px auto;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%, 95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%, 59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    5%, 95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }

    10%, 59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }

    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }

    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }

    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}

@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
